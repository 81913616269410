.form-group {
   
    /* border: 0.5px solid #f9a05f; */
    margin: auto;
    /* margin-top: 50px; */
  
    max-width: 394px;
    border-radius: 0 20px 0 20px;
} 

.label {
    /* padding-left: 5px;
    padding-right: 5px;
    position: relative;
    top: -20px;
    left: 20px; */
    background-color:  #1b2227;
    color: #f9a05f;
    margin-left: 20px;
}

.form-group>input {
    border: 2px solid  #f9a05f;
    /* max-width: 500px; */
    height: 44px;
    border-radius: 0 10px 0 10px;
    background-color:  #1b2227;

}
.form-control{
    background-color:  #1b2227;
}

.outside {
    background-color: #212529;
    max-width: 600px;
    max-height: 50%;
    margin: auto;
    height: 800px;
    padding-top: 50px;
    margin-top: 50px;
}

.button {
    background-color: #f9a05f;
    border: none;
    color: white;
    padding: 10px 32px;
    border-radius: 0 10px 0 10px;
    text-decoration: none;
    margin: auto;
    cursor: pointer;
    width: 392px;
    margin-left: 82px;
}

.login{
    color: #f9a05f;
}

.login-button{
    margin:auto;
}

.other-controls {
    color: white;

    margin-left: 40px
}

.forget-pass {
    color: white;
    display: inline-block;
}

.form-end{
    width: 500px;
    background-color:black;
    margin: 20px auto;
    border-radius: 5px;
    color: whitesmoke;
    height: 102px;
    display: flex;
}

.qr-login{
    background-color:black;
    border-radius: 5px;
    color: whitesmoke;
    margin: auto;
}

.qr-login .text{
    color:#f9a05f;
    margin:0 60px;
}

.imgScan{
    /* height:50px; */
    width: 97px;
    padding: 8px;
}

.end-text{
    color: #f9a05f;
    margin-top: -43px;
    margin-left: 139px;
} 
