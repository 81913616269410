.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* offcanvas */
.menu-item-has-children {
  transition: all 0.5s ease-out;
}
.menu-item-has-children ul {
  transition: all 0.5s ease-in;
}
/* offcanvas end*/
.slick-dots li button:before {
  display: none;
}
.slick-dots li {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  display: block;
}
.react-slick-item-portfolio {
  padding: 0 10px;
}
.testimonial-item-padding {
  padding: 0 15px;
}
.team-slider {
  padding: 0 5px;
}
.slick-dots {
  bottom: auto;
  width: auto;
}
.hero-slider ul.slick-dots li.slick-active button::before {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background: black;
}
.hero-slider .slick-dots {
  display: flex !important;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
.portfolio-2-item:hover .portfolio-overlay {
  left: -200%;
}

/* ADDED BY DASTGEER */
.dash-menu {
  padding-left: 10px;
}

.dashboard-conainer {
  background-color: #f2f2f7;
}

.btn-primary {
  background-color: #f9a05f;
  font-size: 14px;
  color: black;
  border-color: #f9a05f;
  padding: 10px 20px;
  min-width: 150px;
}
.btn-primary > a {
  color: black;
}
.btn-primary:hover {
  color: #f9a05f;
  background-color: white;
  border-color: #f9a05f;
}

.btn-info {
  background-color: #e5e5ea;
  color: black;
  border-color: #e5e5ea;
  padding: 10px 25px;
  min-width: 150px;
}
.btn-info a {
  color: black;
}
.btn-info:hover {
  border-color: #bababa;
  background-color: #bababa;
  color: black;
}

.vertical-middle {
  margin: 0;
  position: absolute;
  top: 50%;
}

.staff-img {
  font-size: 35px;
  color: #aeaeb2;
  background-color: #f2f2f7;
  padding: 40px;
  border: 1px solid #f2f2f7;
  border-radius: 100px;
}

p {
  font-size: 14px;
}

.bread-icon {
  font-size: 12px;
  padding: 0px 15px;
}
.subheading-link {
  font-size: 12px;
  font-weight: 400;
  color: #f9a05f;
}

.form-label {
  font-size: 14px;
}

.input-filed {
  border: 1px solid #f2f2f7 !important;
  background-color: #f2f2f7 !important;
}
.orange-text {
  color: #f9a05f;
}

/* CARD DESIGN */
.radius-12 {
  border-radius: 12px;
  border-color: white;
}

.card-name {
  font-weight: 500;
}

.designation {
  font-weight: 400;
}
.card-id {
  background-color: #f2f2f7;
  padding: 3px 50px;
  display: inline-flex;
  text-align: center;
  /* max-width: 150px; */
  border-radius: 30px;
}

.card-btn {
  min-width: 170px;
}
.main-block {
  cursor: move;
}
.info-ul {
  padding: 0px;
}

.aling-fix {
  display: flex;
  vertical-align: middle;
}

.hide-custom {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.place-oder-preview-btn {
  border-radius: 4px;
  padding: 2px 20px;
}

lable.form-label {
  font-weight: 500;
  color: black;
  font-size: 15px;
}

.btn-primary:focus,
.btn-primary:active {
  background-color: #f9a05f !important;
  box-shadow: none;
  border: none;
  color: black;
  /* other custom styles for the active state */
}

.btn-info:focus,
.btn-info:active {
  background-color: #e5e5ea !important;
  box-shadow: none;
  border: none;
  color: black;
  /* other custom styles for the active state */
}

/* DASHBOARD MAIN PAGE STYLE */

.infoblocks {
  margin: 0px 10px;
  min-width: 160px !important;
  background-color: white;
  margin-left: 0px;
}

.info-ul li {
  min-width: 25%;
}

.mouse-pointer {
  cursor: pointer;
}

.staff-img {
  cursor: pointer;
}

/* Footer Styles */
ul.social {
  margin-left: -98px;
}

.title-bottom-border {
  color: white;
}
.footer-item .footer-info ul li i {
  width: 28px;
  height: 28px;
  left: -4px;
  top: 0px;
  font-size: 13px;
}

.package-container {
  background-color: #f6f6f6;
}

.head-img {
  height: 300px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.items-table {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.discount-price {
  display: block;
  font-size: 14px;
  color: red;
  font-style: italic;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.infoblocks {
  /* padding-top:12px; */
  height: 86px;
}

.income-block {
  background-color: #f9a05f;
  color: white;
  padding: 0;
}

.income-block::before {
  border-bottom: #f9a05f;
}

.income-block .week-progress {
  font-size: 12px;
  padding: 0 10px;
  background-color: black;
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: left;
}

.income-block .week-progress .week {
}

.income-block .week-progress .figure {
  float: right;
  color: #53c22b;
}

.income-block .info-number {
  color: white;
}

.info-number {
  padding-top: 2px;
}

.arrow {
  /* Vector */
  width: 19px;
  height: 11px;
  background: #53c22b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.category-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.category-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-list li {
  margin-right: 40px;
}

.category-item {
  flex: 0 0 auto;
  margin-right: 10px;
}

.category {
  width: 75px;
  height: 75px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.category img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category span {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 4px 8px;
}

.cart-ribbon {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.question-mark {
  cursor: pointer;
}

.Toastify__toast--success {
  color: black !important;
  background-color: #f9a05f !important;
}

/* Media query */
@media only screen and (max-width: 767px) {
  /* Your CSS styles for mobile devices go here */
  .mobile-p-t {
    padding-top: 90px;
  }
}

.terms_ul {
  list-style: none;
}

.terms_ul li:before {
  content: '✓';
  padding-right: 10px;
}