

@keyframes seamless-area-animation1 {
  0%   {
    top: 20px;
  }
  50%  {
   top:120px;
  }
  100% {
     top:20px;
  }
}

.seamless-right-image1 {
    left: -90px;
    position: relative;
    top: -91px;
  
    animation-name: seamless-area-animation1;
    animation-duration: 02s;
    animation-iteration-count: infinite;
  }
  .seamless-animation-block-area1 {
    display: flex;
    flex-direction: row;
      justify-content: flex-end;
  }

  @keyframes seamless-area-animation2 {
    0%   {
      top: 80px;
    }
    
    50% {
       top:190px;
    }
    100%{
      top:80px;
    }
  }


.seamless-right-image2 {
    left: -90px;
    position: relative;
    top: 91px;
  
    animation-name: seamless-area-animation2;
    animation-duration: 02s;
    animation-iteration-count: infinite;
  }
  .seamless-animation-block-area1 {
    display: flex;
    flex-direction: row;
      justify-content: flex-end;
  }
  
