.toggle-list{
    /* display: inline-block; */
    /* border-bottom: 1px solid gray; */
}



.toggle-list li {
    display: inherit;
    padding-top: 10px;
    font-weight: 500;
    font-size: 17px;
    margin-right: 15px;
    cursor: pointer;

}

.toggle-list li:hover{
    cursor: pointer;
}

.active-toggle{
    color:#F9A05F;
    border-bottom:3px solid #F9A05F;
    z-index: 9;
}

ul.toggle-list {
    display: flex;
    cursor: pointer;
} 

button.small{
    padding:6px 30px;
    margin-right:5px;
    margin-left:5px;
    font-size: 13px;
}

ul.filter {
    padding: 0px;
    margin-bottom: 0px;
    display:flex;
}

ul.filter li:after {
    border-right: 1px solid black;
}

.btn-filter ul li {
    border: 1px solid gray !important;
    padding-right: 20px !important;
    border-radius: 0px;
}

li p.orange-text {
    margin-bottom: 0px;
    padding-top: 4px;
}
input.order-search {
    background: #F5F6FA;
    border: 0px;
    min-width: 100%;
    padding: 4px 16px;
}

span.filteroption{
    font-size: 13px;
    border-radius: 0px;
}
