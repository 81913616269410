.menu-toggle-list1{
    display: inline-block;
    /* border-bottom: 1px solid gray; */
}

.menu-toggle-list1 li {
    display: inherit;
    /* padding: 10px; */
    font-weight: 500;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 19px;

}

.menu-toggle-list1 li:hover{
    cursor: pointer;
}

ul.menu-toggle-list1 {
    padding-left: 0px;
    
}

input[type=checkbox]{
    width:24px;
    height:24px;
    margin-right:20px;
    /* -webkit-appearance: none; */
    background-color: #b1b1b0;
    border-radius: 3px;
    cursor: pointer;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

input[type=checkbox]:after{
    font-family: "Font Awesome 5 Free";
    /* content:"\f00c"; */
    font-weight: 800;
    font-size: 15px;
    color:white;
    padding: 5px;
}
/* input[type=checkbox]:checked{
    background-color: #F9A05F !important;
} */

input[type="checkbox"]:checked {
    background-color: #F9A05F;
    border-radius: 2px;
  }

input[type=checkbox]:checked:after{
    display: inline;
}

.time-select{
    max-width: 150px;
}
span.day{
    margin-top:-5px;
}

.number-field{
    max-width: 80px;
}

.react-switch-bg {
    background: #F9A05F !important;
}


@media only screen and (max-width: 768px) {
    .menu-toggle-list1{
        margin-right: 0px;
        margin-left: 0px;
    }
  }