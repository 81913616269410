/* input[type="checkbox"]:checked {
    background-color: orange;
    border-radius: 2px;
  } */
  

  input[type="checkbox"]:checked {
    background-color: #F9A05F;
    border-radius: 4px;
  }
  
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid white;
    height: 12px;
    width: 12px;
    border-radius: 4px !important;
    outline: none;
    transition: all 0.3s ease-in-out;
  }
  
  input[type="checkbox"]:checked:before {
    content: "\2713";
    font-size: 19px;
    color: white;
    padding-left:3px;
    text-align: center;
    line-height: 18px;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  .date-calendar{
    color:#F9A05F;
  }

  span.input-group-text {
    background: #f2f2f7;
    border: none;
}

span.time-slot {
  display: inline-block;
  /* margin-top: 20px; */
  background: #F5F6FA;
  padding: 4px 19px;
  border: 1px solid #D5D5D5;
  border-radius: 25px;
  margin-right:10px;
  margin-bottom: 15px;
}

span.time-slot.active {
  background: #f9a05f;
  color: black;
}

