.btn.small-btn {
    padding: 03px 23px;
    font-size: 14px;
    color:white;
}

.btn-warning{
    background-color: #E2A63B !important;
}


input.green:checked {
    background-color: #61AD5A !important;
  }
  
  input.green {
    background-color: #E6034B !important;
  }

  /* input[type="checkbox"].green:not(:checked) + label::before {
    content: "x" !important;
    z-index: 99;
  } */
  input[type="checkbox"].green:not(:checked){
    content: "x" !important;
    z-index: 99;
  }

  i.fa.fa-exclamation {
    color:#F9A05F;
    font-size: 49px;
    border: 3px solid #F9A05F;
    padding: 20PX;
    border-radius: 100px;
    width: 90px;
    height: 90px;
    text-align: center;
    vertical-align: center;
    margin-top:50px;
}

button.btn.btn-delete{
    background-color: #E6034B;
    color: black;
    border-color: #E6034B;
    color:white;
    padding: 10px 25px;
    min-width: 150px;
}
  