.heading{
    font-size: 20px;
    font-weight: 700;
}

p.dim-text{
    color:#AEAEB2;
}

.btn-copy{
    max-width: 50px !important;
}