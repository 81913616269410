/* h2.heading{
    font-size: 20px;
    font-weight: 700;
} */

button.btn-filter{
  max-width: 120px !important;
} 

input.form-controls {
  background: #D5D5D5;
  border-color: #D5D5D5;
  border:1px solid #D5D5D5;
  margin-right: 5px;
  padding:10px 10px;
}

input.searchbtn{
  background: #F5F6FA;
  border:1px solid #D5D5D5;
  margin-right: 5px;
  padding-top:20px;
  padding-bottom:20px;
}

.inline{
  display: flex;
}