/* .topimg{
    z-index: 1 !important;
}

.qr-after-header{
    z-index: 9;
    background-color: red;
    border-radius: 20px;
    margin-top: -20px;
} */
/* .top-radius{
    border-top-right-radius: 10px !important;

    z-index: 3;
    background-color: gray !important;
} */

.head-img {
  z-index: 1;
  position: inherit;
}

.header2 {
  /* border: 2px solid red; */
  border-radius: 15px;
  background: white;
  margin-top: -20px;
  z-index: 4 !important;
  position: relative;
  display: flex;
}

.qr-logo-main {
  margin-top: -35px !important;
  /* padding-left: 10px; */
  width: 80px;
  height: 80px;
  border-radius: 16px;
  border-color: #d5d5d5;
  overflow: hidden;
  position: relative;
}

.qr-logo-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.qr-social-media.icon-social {
  /* padding-left: 50% !important; */
  padding-top: 10px;
  width: 77%;
  text-align: right;
  padding-right: 25px;
}

.social-media-icons li {
  padding-left: 11px;
  font-size: 15px;
}

.qr-scan-title h3 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
  /* width:60%; */
}

.qr-scan-description h5 {
  font-size: 12px;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.qr-scan-description p {
  font-size: 10px;
  line-height: 1;
}

.order-container h6 {
  font-size: 12px;
  margin: 0;
}

.green-text {
  /* width: 40%; */
  color: #3eea00;
  margin-left: 100px;
}

.inline-content {
  display: flex;
}

.qr-scan-ordering-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}

.left-btn-content span {
  color: black;
}

button#dropdown-basic {
  padding: 0px;
  margin-top: -12px;
}

.row.scroler {
  max-height: 110px;
  min-height: 110px;
  display: flex !important;
  overflow-x: auto;
}

/* .scroler::-webkit-scrollbar{
    width: 0px;
} */

.scroler .categories-list {
  min-width: 100px;
  min-height: 100px;
}

.cad-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.popup-product-area {
  position: fixed;
  height: 100%;
  margin: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 0px !important;
  padding: 0px !important;
  background-color: #0000008c;
}

.wrapper {
  position: relative;
  background-color: white;
  margin-top: 109%;
  height: 50%;
  margin-left: 0px;
  margin-right: 0px;
  padding: 15px;
  padding-bottom: 100px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.checkout-cart-area {
  position: fixed;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-bottom: 0px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.checkout-cart-area-paynow {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-bottom: 0px;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.mobile-heading {
  font-size: 15px;
  font-weight: 700;
}

.tip-amoun {
  background-color: #dfe0e1;
  padding: 3px 14px 3px 14px;
  border-radius: 40px;
  margin-right: 4px;
}

.orange-bg {
  background-color: #f9a05f;
}

.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.537);
}

.main-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-bottom: 0px;
}

.inner-wrapper {
  border-radius: 15px;
}

.inner-wrapper1 {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.top1 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.white-text {
  color: white;
}

.cart-ribbon {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.top {
  margin-bottom: 10px;
}

button.btn.btn-blue {
  background: #1769ab;
  border-radius: 0px;
  color: white;
  min-width: 100px;
}
.bottom-wrapper {
  border-radius: 15px;
}

.blue-text {
  padding: 15px;
  color: #1769ab;
  font-size: 19px;
  font-size: 500;
}
.btn-blue > a {
  color: white;
}

input.form-control.mobile-field.pl-2 {
  background: #f2f2f7;
  margin-left: 9px;
}

.full-height {
  height: 98vh;
  background-color: white;
}

.input-group {
  position: relative;
}

.input-group input {
  padding-right: 70px; /* adjust the padding to fit the button */
}

.input-group button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

button.btn.btn-primary.from-btn {
  max-width: 60px !important;
  padding: 7px 10px;
  min-width: 96px;
}

.input-group.py-0.my-0.move-up {
  margin-top: -50px !important;
}

i.fa.fa.fa-chevron-right.pr-3 {
  color: black;
}

.left-btn-content {
  justify-content: start;
  width: 99px;
}

span.time {
  display: inline-block;
  /* margin-top: 20px; */
  color: #8e8e93;
  background: #f5f6fa;
  padding: 4px 10px;
  border: 1px solid #d5d5d5;
  border-radius: 25px;
  margin-right: 9px;
  margin-bottom: 10px;
  font-size: 13px;
}

.selected {
  color: black !important;
  background: #f9a05f !important;
}

.not-available {
  color: #8e8e93 !important;
  background: #f5f6fa !important;
}

span.time.active {
  background: #f5f6fa;
  color: black;
}

.jVBqfj {
  --moedim-primary: #f9a05f !important;
  border: none !important;
  border-radius: 4px;
}

button.btn.my-btn {
  background: #f9a05f;
  padding: 7px 54px;
  color: black;
}

.mobile-btn {
  width: 230px;
}

.hour-btn {
  font-size: 10px;
  position: relative;
  padding-left: 10px;
}

.open-hour {
  color: #3eea00;
}

.close-hour {
  color: red;
}

.open-hour::before {
  background-color: #3eea00;
}

.close-hour::before {
  background-color: red;
}

.hour-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .qr-scan-title h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    /* width:80%; */
  }

  .green-text {
    /* width: 20%; */
    color: green;
  }
}
