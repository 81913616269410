div.standard {
    border: 2px solid #F9A05F;
    border-radius: 10px;
}

div.premium{
    background-color: black;
    border-radius: 10px;
    color:white;
}

.orange-text {
    color: #F9A05F !important;
}

p.sub-price{
    font-size: 14px;
    font-weight: 500;
    color:black;
}

p.sub-premium-price{
    font-size: 14px;
    font-weight: 500;
    color:#AEAEB2;
}

button.btn-standard{
    background-color: white;
    color:#F9A05F;
    font-size: 14px;
    border:2px solid #979797;
    border-radius: 5px;
}

button.btn-premium{
    background-color: #F9A05F;
    color:black;
    font-size: 14px;
    border:2px solid #F9A05F;
    border-radius: 5px;
}