p.subheading{
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
}

span.price-switch{
    margin-top:10px;
    padding-left: 40px;
    padding-right: 40px;
}

span.price-sale {
    border: 1px solid #F9A05F;
    padding: 6px 15px;
    margin-left: 11px;
    border-radius: 30px;
    font-size: 11px;
    color: #F9A05F;
    background-color: #F9A05F1A;
}