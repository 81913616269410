/*
    Name      : Yawaevig 
    Author    : Sayed Ali Kumail
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/font-awesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url("./../icons/icomoon/icomoon.css");
@import url("./../icons/bootstrap-icons/font/bootstrap-icons.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/aos/css/aos.min.css);
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);

:root {
  --primary-color: #f9a05f;
  --secondary-color: #1c1c1e;
  --primary-color-hover: var (--primary-color);
  --primary-color-dark: var (--secondary-color);
  --primary-color-bg: var (--primary-color);
  --secondary-color-bg: var (--secondary-color);

  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --title: #000;
  --text: #8e8e93;
}

ul {
  padding: 0;
  margin: 0;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
li {
  list-style: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}

h6,
.h6 {
  font-size: 0.938rem;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  line-height: 1.5;
  font-family: Poppins, sans-serif !important;
}

small,
.small {
  font-size: 0.875em;
}
body {
  font-family: Poppins, sans-serif;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #393939;
  background-color: #f8f8f8;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
}

.header {
  height: 6rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background: #1c1c1e;
  z-index: 3;
  padding-left: 20.563rem;
  padding-top: 0rem;
  transition: all 0.2s ease;
}
.header .header-content {
  height: 100%;
  padding-left: 7rem;
  padding-right: 1.875rem;
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 87.5rem) {
  .header .header-content {
    padding-left: 5rem;
  }
}
@media only screen and (max-width: 74.9375rem) {
  .header .header-content {
    padding-left: 2rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-left: 2.75rem;
    padding-right: 0.938rem;
  }
}
.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}
.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 87.5rem) {
  .header {
    height: 5.5rem;
  }
}
@media only screen and (max-width: 74.9375rem) {
  .header {
    height: 5rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  .header {
    padding-top: 0;
  }
}
#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  position: relative;
}
#main-wrapper.show {
  opacity: 1;
}
.nav-header {
  height: 6rem;
  width: 20.5rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 0;
  background-color: var(--nav-headbg);
  transition: all 0.2s ease;
  z-index: 5;
}
.nav-header .logo-abbr {
  max-width: 2.9375rem;
}
@media only screen and (max-width: 87.5rem) {
  .nav-header .logo-abbr {
    max-width: 2.8125rem;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .nav-header .logo-abbr {
    width: 2.1875rem;
    height: 2.1875rem;
  }
}
.nav-header .logo-compact {
  display: none;
}
@media only screen and (max-width: 87.5rem) {
  .nav-header {
    height: 5.5rem;
    width: 17rem;
  }
}
.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  font-weight: 700;
}
@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  font-weight: 700;
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -5rem;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 0.125rem 0.5rem 0;
  border-radius: 0.125rem;
}
@media only screen and (max-width: 87.5rem) {
  .nav-control {
    right: 0.444rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    right: -3rem;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

.browser-toggle .hamburger {
  display: flex;
  left: 0rem;
  position: relative;
  top: 0rem;
  transition: all 0.3s ease-in-out 0s;
  z-index: 999;
  background: #f9a05f;
  width: 35px;
  height: 35px;
  transform: rotate(43deg);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.browser-toggle .menu-hum {
  transform: rotate(316deg);
  color: #1c1c1e;
}

.browser-toggle .hamburger:hover {
  cursor: pointer;
}

.browser-toggle {
  top: 10%;
  right: -1.9rem;
}

.browser-toggle .hamburger.is-active .menu-hum {
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
  color: #1c1c1e;
}
.hamburger {
  display: inline-block;
  left: 0rem;
  position: relative;
  top: 0rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
  color: #f9a05f;
}

.hamburger .line {
  background: #f9a05f;
  display: block;
  height: 0.1875rem;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 1.625rem;
}

.hamburger .line:nth-child(2) {
  width: 1.625rem;
}

.hamburger .line:nth-child(3) {
  width: 0.9375rem;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
  transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
  transform: translateY(-4px) translateX(12px) rotate(-45deg);
}
.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-left .breadcrumb {
  margin-bottom: 0;
}
.header-left .agenda a {
  border-radius: 1.75rem;
}
.header-left .agenda a i {
  margin-right: 0.75rem;
}
@media only screen and (max-width: 87.5rem) {
  .header-left .agenda a i {
    margin-right: 0rem;
  }
}
@media only screen and (max-width: 93.75rem) {
  .header-left .agenda a {
    font-size: 0;
    border-radius: 0.75rem;
    padding: 0.8125rem 0.9375rem;
  }
  .header-left .agenda a i {
    font-size: 1.25rem;
    margin-right: 0;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .header-left .agenda {
    display: none;
  }
  .mob-remove {
    display: none !important;
  }
  .upcoming-past-tab {
    font-size: 7px;
  }
  .search{
    font-size: 7px !important;
  }
  .order-detail{
    font-size: 10px !important ;
  }
  .qr-slip{
    top: 25%;
    left: 38%;
    width: 350px;
  }
  .addstaff-mob{
     padding: 0px !important;
     padding-left : 0px !important;
     padding-right : 0px !important;
  }
  .avail{
    width: 100px;
  }
  .serv{
    margin-left: 0px !important;
  }
  .btn-width{
    width: 200px !important;
    justify-content:  center !important;
    display: flex;

  }
  .over-flow-block{
    overflow: hidden !important;
  }
  .btn-txt-centr {
    text-align: center !important; 
  }
  .qr-mob {
    width: 150px !important;
  }
  .qr-heading-text {
    word-wrap: break-word;
  }
  .setting-mob{
    margin-left: 3rem !important;
  }
  .setting-tab{
    display: flex !important;
    flex-direction: row;
    font-size: 12px !important;
  }
  .head-mob{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.header-left .dashboard_bar {
  font-size: 2rem;
  font-weight: 800;
  color: #000;
}
@media only screen and (max-width: 74.9375rem) {
  .header-left .dashboard_bar {
    font-size: 2.125rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  .header-left .dashboard_bar {
    display: none;
  }
}
.header-left .dashboard_bar.sub-bar {
  font-size: 1.875rem;
}

.header-right {
  height: 100%;
  background: #1c1c1e;
}
.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-right .nav-item .nav-link {
  color: #464a53;
}
.header-right .right-sidebar {
  margin-right: -1.875rem;
}
.header-right .right-sidebar a {
  height: 5rem;
  width: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 0.0625rem solid #c8c8c8;
}
.header-right > li:not(:first-child) {
  margin-left: 1.25rem;
}
@media only screen and (max-width: 74.9375rem) {
  .header-right > li:not(:first-child) {
    padding-left: 1rem;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .header-right > li:not(:first-child) {
    padding-left: 0.4rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown {
    position: static;
  }
}
.header-right .notification_dropdown .nav-link {
  position: relative;
  color: #f9a05f;
  border-radius: 1.25rem;
  padding: 0.9375rem;
  line-height: 1;
}
@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link {
    padding: 0.625rem;
  }
}
.header-right .notification_dropdown .nav-link i {
  font-size: 1.5rem;
}
@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 0.625rem;
  border-radius: 50%;
  right: 0rem;
  top: -0.3rem;
  font-weight: normal;
  height: 1.938rem;
  width: 1.938rem;
  line-height: 1.125rem;
  text-align: center;
  padding: 0.3125rem;
  font-size: 0.875rem;
}
@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link .badge {
    height: 1.25rem;
    width: 1.25rem;
    border-width: 0.125rem;
    line-height: 0.4375rem;
    font-size: 0.5625rem;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link .badge {
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    line-height: 0.9375rem;
    right: 0rem;
    top: -0.125rem;
  }
}
.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}
.header-right .notification_dropdown .dropdown-item a {
  color: #6e6e6e;
}
.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}
.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 2.3125rem rgba(8, 21, 66, 0.05);
}
[data-theme-version="dark"] .header-right .dropdown-menu {
  box-shadow: none;
}
.dropright .dropdown-toggle::after {
  content: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.dlabnav {
  width: 20.5rem;
  padding-bottom: 0;
  height: calc(100% - 6rem);
  min-height: 100vh;
  position: absolute;
  top: 6rem;
  padding-top: 0;
  z-index: 6;
  background-color: #1c1c1e;
  transition: all 0.2s ease;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    top: 5.5rem;
    height: calc(100% - 78px);
  }
}
@media only screen and (max-width: 74.9375rem) {
  .dlabnav {
    top: 5rem;
    height: calc(100% - 70px);
  }
}
@media only screen and (max-width: 47.9375rem) {
  .dlabnav {
    width: 18rem;
  }
}
.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    width: 17rem;
  }
}
.dlabnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.dlabnav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 0.9375rem;
}
.dlabnav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}
.dlabnav .metismenu > li {
  display: flex;
  flex-direction: column;
}
.dlabnav .metismenu > li a > i {
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  text-align: center;
  margin-right: 1.25rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}

/* My Css */

.active-border {
  margin-bottom: 4px;
  border: 1px solid;
  border-image-source: radial-gradient(
    100% 3025% at 0% 50%,
    rgba(249, 160, 95, 0.3) 0%,
    #f9a05f 52.08%,
    rgba(249, 160, 95, 0.3) 100%
  );
  border-image-slice: 2;
}

.header-icon {
  background: #2c2c2e;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}
.header-button {
  background: #2c2c2e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  padding: 0px 30px;
  height: 40px;
}
.person-name {
  color: white;
  margin-right: 20px;
}
.header-button-text {
  margin-left: 5px;
}
.header-profile::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 29px;
  height: 31px;
  background-color: #3a3a3c;
}

.dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dlabnav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 0.9375rem;
}
.dlabnav .metismenu li {
  position: relative;
  display: flex;
  flex-direction: column;
}
.dlabnav .metismenu > li > a {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 1rem 2.1875rem;
  transition: all 0.5s;
}
.dlabnav .metismenu > li > a {
  color: #8e8e93;
  display: inline-block;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.dlabnav .metismenu ul {
  padding: 0.5rem 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.dlabnav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.show .justlogo {
  display: none;
}
.menu-toggle .justlogo {
  display: block;
}
.dlabnav .metismenu li {
  position: relative;
}
.dlabnav .metismenu ul a:hover:before {
  width: 1.25rem;
}
.dlabnav .metismenu ul a:hover,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}
.dlabnav .metismenu ul a:before {
  position: absolute;
  content: none;
  height: 0.125rem;
  width: 0.5rem;
  top: 50%;
  left: 1.75rem;
  border-radius: 3.75rem;
  transform: translateY(-50%);
}
@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu ul a:before {
    left: 2.8125rem;
  }
}
.dlabnav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #8e8e93;

  text-decoration: none;
}
@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}
.dlabnav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #c8c8c8;
  border-left: 0.3125rem solid #c8c8c8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 1.875rem;
  top: 48%;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}
.dlabnav .metismenu .has-arrow[aria-expanded="true"]:after,
.dlabnav .metismenu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  /* margin-right: 2rem; */
}
.dlabnav .main-menu {
  padding: 0.625rem 1.875rem;
}
.dlabnav .header-info2 {
  padding: 0.625rem 1.25rem;
  margin-top: 1rem;
  border-radius: 1.25rem;
}
.dlabnav .header-info2 img {
  height: 3.563rem;
  width: 3.563rem;
  border-radius: 0.875rem;
  margin-right: 0.8rem;
}
.dlabnav .header-info2 span {
  font-size: 1.25rem;
  color: #171b1e;
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 span {
    font-size: 0.9rem;
  }
}
.dlabnav .header-info2 i {
  margin-left: 1rem;
  font-size: 1.2rem;
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 {
    padding: 0;
  }
}
.dlabnav .metismenu a {
  color: #8e8e93;
  display: block;
  outline-width: 0;
  padding: 0.625rem 1.875rem;
  position: relative;
  text-decoration: none;
}
.dlabnav .metismenu ul a.mm-active,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a:hover {
  color: #f9a05f;
  text-decoration: none;
}
.dlabnav .metismenu ul a {
  font-size: 0.9375rem;
  padding-bottom: 0.5rem;
  padding-left: 5rem;
  padding-top: 0.5rem;
  position: relative;
  transition: all 0.5s;
}
.dlabnav .metismenu ul a:before {
  background: rgba(68, 129, 78, 0.5);
  content: "";
  height: 0.125rem;
  left: 2.5rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s;
  width: 0.375rem;
}
.header-profile::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 29px;
  height: 31px;
  background-color: #3a3a3c;
}
.image-area {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.image-area img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.header-profile-area {
  display: flex;
  align-items: center;
}

.person-name {
  color: white;
  margin-right: 20px;
  margin-left: 15px;
}
.porifle-picture {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
}
.drop-down-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.profile-user-name {
  margin: 0;
  font-weight: bold;
  line-height: 10px;
}
.profile-user-email {
  margin: 0;
  font-size: 12px;
  color: #8e8e93;
}
.profile-details {
  padding: 0px 10px;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
}
.dlabnav .dlabnav-scroll {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.menu-toggle .dlabnav .dlabnav-scroll {
  overflow: visible !important;
}
.menu-toggle .dlabnav {
  width: 6.5rem;
  overflow: visible;
  position: absolute;
}
.menu-toggle .dlabnav {
  position: fixed;
}
.menu-toggle .nav-header .brand-logo .brand-title {
  display: none;
}
.menu-toggle .dlabnav .metismenu > li > a {
  padding: 1.25rem 1.125rem;
  text-align: center;
  border-radius: 3rem;
}
.menu-toggle .dlabnav .nav-text {
  display: none;
}
.dlabnav .metismenu > li {
  transition: all 0.4s ease-in-out;
  padding: 0 2rem;
}
.menu-toggle .dlabnav .metismenu .has-arrow:after {
  display: none;
}
.menu-toggle .dlabnav .metismenu li > ul {
  position: absolute;
  left: 5.8rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 0.0625rem;
  height: auto !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgb(82 63 105 / 10%);
  border-radius: 0.375rem;
  margin-left: 0;
  border: 0;
  background: #fff;
}
.menu-toggle .dlabnav .metismenu > li > a {
  padding: 1.25rem 1.125rem;
  text-align: center;
  border-radius: 3rem;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 2;
  overflow: hidden;
  border-radius: 1.25rem;
  box-shadow: 0 0 3.125rem 0 rgb(82 63 105 / 15%);
  margin-top: 0;
}

.tab-content > .active {
  display: block;
}
.fade {
  transition: opacity 0.15s linear;
}
.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 1.25rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
  height: calc(100% - 30px);
}
.chatbox .card {
  box-shadow: none;
}
.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}
.chatbox .card-header {
  background: #f4f7ff;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
}
.card-header:first-child {
  border-radius: calc(1.25rem - 1px) calc(1.25rem - 1px) 0 0;
}
.card-header {
  border-color: #d7dae3;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active-border {
  color: white !important;
}

.menu-toggle .dlabnav .metismenu > li {
  padding: 0 1rem !important;
}

.content-body {
  margin-left: 20.563rem;
  z-index: 0;
  padding: 25px;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 87.5rem) {
  .content-body {
    margin-left: 17rem;
  }
}
.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

.menu-toggle .content-body {
  margin-left: 6.5rem;
}

@media only screen and (max-width: 768px) {
  .menu-toggle .dlabnav {
    left: 0;
  }

  .dlabnav {
    transition: all 0.5s;
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 20%);
  }
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: #19191ba3;
  border: 0;
  justify-content: space-between;
}
.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: var(--primary-color);
  opacity: 1;
  border-color: var(--primary-color);
}
.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary-color);
  border-radius: 0;
  color: var(--primary-color);
  border-width: 0 0rem 0.1875rem 0;
}
.chatbox .nav .nav-link {
  color: white;
  opacity: 1;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8f8f8;
  border-color: #dee2e6 #dee2e6 #f8f8f8;
}
.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}

.dlabnav .metismenu > li > a:hover {
  border: 1px solid;
  border-image-source: radial-gradient(
    100% 3025% at 0% 50%,
    rgba(249, 160, 95, 0.3) 0%,
    #f9a05f 52.08%,
    rgba(249, 160, 95, 0.3) 100%
  );
  border-image-slice: 2;
  color: white;
}
.dashbaord-date-filter li {
  display: inline-block;
  padding: 0px 5px;
}

.info-number {
  display: block;
  color: #f9a05f;
  font-size: 18px;
  font-weight: 600;
}
.info-text {
  font-size: 12px;
  font-weight: 600;
}
.infoblocks {
  margin: 0px 10px;
  /* min-width: 140px !important; */
  background-color: white;
}
.infoblocks {
  background: white;
  /* max-width: fit-content; */
  text-align: center;
  padding: 15px 20px;
  border-radius: 8px;
  position: relative;
}
.infoblocks:before {
  content: "";
  position: absolute;
  left: 25px;
  bottom: 0;
  height: 15px;
  width: 70%;
  border-bottom: 3px solid var(--primary-color);
}
.info-ul li {
  display: inline-block;
  margin-left: 15pxpx;
  margin-bottom: 20px;
}
.btn-close {
  border: 0;
  border-radius: 1.25rem;
  box-sizing: content-box;
  color: #000;
  opacity: 0.5;
  font-size: 17px;
}

.alert-dismissible .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.h3,
h3 {
  font-size: 1.3rem;
}
.alerts-card {
  max-height: 80vh;
  overflow: scroll;
}
.chart-block {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
}

.menu-item-title {
  color: #2c2c2e;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0;
}
.meni-image {
  height: 60px;
  margin-right: 10px;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}
.menu-item-price {
  color: #f9a05f;
  float: right;
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 0px;
}
.menu-block-table {
  width: 100%;
}
.menu-item-description {
  margin-bottom: 0px;
}
.menu-item-search-area {
  margin-bottom: 20px;
}
.inner-header {
  font-size: 20px;
  margin-bottom: 0px;
}
.title-sub-text {
  color: #aeaeb2;
  font-size: 12px;
  margin: 0;
  line-height: 12px;
}
.menu-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.main-btn-add {
  font-weight: 500;
}
.plus-icon {
  margin-right: 10px;
  font-size: 16px;
}

.btn-theme-main {
  background: #f9a05f;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
  color: #1c1c1e;
  border: none;
  transition: 0.3s;
}

.btn-theme-main:hover {
  background: #1c1c1e;
  color: #f9a05f;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 8px 8px 0px 0px;
}

.header-left-row ul {
  padding: 0px;
  margin: 0;
}

.header-left-row ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 700;
}
.exp-coll {
  font-weight: 700;
  cursor: pointer;
}

.category-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-top: 10px;
  margin-bottom: 8px;
}
.category-title h5 {
  margin: 0px;
}
.category-description p {
  margin: 0px;
}
.category-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.category-title {
  margin: 0px 12px;
}
.category-left {
  display: flex;
  align-items: center;
}
.category-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-top: 10px;
  margin-bottom: 8px;
  padding: 10px 20px;
}
.left-menu-item {
  width: 95%;
  margin-left: 5%;
}
.main-block {
  cursor: move;
  padding: 0px 20px 0px 10px;
}
.products-collapse {
  display: none;
}
.sorting-handle {
  color: #d9d9d9;
}
.drop-down-hr {
  margin: 3px auto;
  border: solid 1px #c7c7cc;
  max-width: 80%;
}
.dropdown-item {
  color: #2c2c2e !important;
}
.dropdown-menu {
  font-size: 14px !important;
}
.dashbaord-header-button li {
  display: inline-block;
  padding: 0px 10px;
}
.btn-theme-secondary {
  background: #e5e5ea;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
  color: #1c1c1e;
  border: none;
  transition: 0.3s;
}
.btn-theme-secondary:hover {
  color: #f9a05f;
  background: black;
}
.slim-button-theme {
  padding: 3px 30px;
  min-width: 130px;
}
.dashbaord-header-nav {
  padding: 0;
  border-bottom: 1px solid #c7c7cc;
  width: 50%;
}
.dashbaord-header-nav li {
  padding: 5px 10px;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}
.dashbaord-header-nav li:hover {
  border-bottom: 2px solid #f9a05f;
}
.active-menu-nav {
  border-bottom: 2px solid #f9a05f;
}
.secondary-color-link {
  color: #33415c;
}
.template-filter-menu {
  padding: 0px;
}
.template-filter-menu li {
  display: inline-block;
}
.template-filter {
  border: none;
  background: white;
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 12px;
  border-radius: 50px;
  transition: 0.3s;
}
.template-filter:hover {
  background: #f9a060;
}
.active-template-filter {
  background: #f9a060;
}
.template-image {
  border-radius: 8px;
  overflow: hidden;
}
.template-image img {
  min-height: 300px;
}
.template-area-block {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  min-height: 300px;
}
.template-details {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.dashbaord-themplate-button {
  padding: 0;
  text-align: center;
}
.template-details {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
}
.dashbaord-themplate-button li {
  display: inline-block;
  margin: 0px 5px;
}
.themplate-btn {
  background: #f9a060;
  border: 0;
  padding: 5px 20px;
  border-radius: 3px;
}
.themplate-btn:hover {
  color: #f9a060;
  background: black;
}
.template-content p {
  margin: 0px;
  line-height: 18px;
  font-weight: 500;
}

.template-details:hover > .template-buttons {
  animation-name: template-btn-down;
  animation-duration: 1s;
  bottom: 20px;
}
.template-details:not(:hover) > .template-buttons {
  animation-name: not-template-btn-down;
  animation-duration: 1s;
}
.template-details:not(:hover) > .template-content {
  animation-name: not-template-top;
  animation-duration: 1s;
}
.template-details:hover > .template-content {
  animation-name: template-top;
  animation-duration: 1s;
  top: 20px;
}
.template-content {
  position: absolute;
  top: -100px;
  text-align: center;
  width: 100%;
}
.template-buttons {
  position: absolute;
  bottom: -100px;
  text-align: center;
  width: 100%;
}
@keyframes template-top {
  0% {
    top: -100px;
  }
  100% {
    top: 20px;
  }
}
@keyframes template-btn-down {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 20px;
  }
}
@keyframes not-template-top {
  0% {
    top: 20px;
  }
  100% {
    top: -100px;
  }
}
@keyframes not-template-btn-down {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: -100px;
  }
}
.active-item-template {
  border: solid 2px #f9a060;
  border-radius: 12px;
  width: 254px;
}
.active-item-template {
  border: solid 2px #f9a060;
  border-radius: 12px;
  width: 254px;
}

.dashbaord-edit-menu-nav {
  padding: 0;
  border-bottom: 1px solid #c7c7cc;
}
.dashbaord-edit-menu-nav li {
  padding: 5px 10px;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}
.dashbaord-edit-menu-nav li:hover {
  border-bottom: 2px solid #f9a05f;
}

.active-border {
  margin-bottom: 4px;
  border: 1px solid;
  border-image-source: radial-gradient(
    100% 3025% at 0% 50%,
    rgba(249, 160, 95, 0.3) 0%,
    #f9a05f 52.08%,
    rgba(249, 160, 95, 0.3) 100%
  );
  border-image-slice: 2;
}

.header-icon {
  background: #2c2c2e;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}
.header-button {
  background: #2c2c2e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  padding: 0px 30px;
  height: 40px;
}
.person-name {
  color: white;
  margin-right: 20px;
}
.header-button-text {
  margin-left: 5px;
}
.header-profile::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 29px;
  height: 31px;
  background-color: #3a3a3c;
}

.dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dlabnav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 0.9375rem;
}
.dlabnav .metismenu li {
  position: relative;
  display: flex;
  flex-direction: column;
}
.dlabnav .metismenu > li > a {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 1rem 2.1875rem;
  transition: all 0.5s;
}
.dlabnav .metismenu > li > a {
  color: #8e8e93;
  display: inline-block;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.dlabnav .metismenu ul {
  padding: 0.5rem 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.dlabnav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.show .justlogo {
  display: none;
}
.menu-toggle .justlogo {
  display: block;
}
.dlabnav .metismenu li {
  position: relative;
}
.dlabnav .metismenu ul a:hover:before {
  width: 1.25rem;
}
.dlabnav .metismenu ul a:hover,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}
.dlabnav .metismenu ul a:before {
  position: absolute;
  content: none;
  height: 0.125rem;
  width: 0.5rem;
  top: 50%;
  left: 1.75rem;
  border-radius: 3.75rem;
  transform: translateY(-50%);
}
@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu ul a:before {
    left: 2.8125rem;
  }
}
.dlabnav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #8e8e93;

  text-decoration: none;
}
@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}
.dlabnav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #c8c8c8;
  border-left: 0.3125rem solid #c8c8c8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 1.875rem;
  top: 48%;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}
.dlabnav .metismenu .has-arrow[aria-expanded="true"]:after,
.dlabnav .metismenu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  /* margin-right: 2rem; */
}
.dlabnav .main-menu {
  padding: 0.625rem 1.875rem;
}
.dlabnav .header-info2 {
  padding: 0.625rem 1.25rem;
  margin-top: 1rem;
  border-radius: 1.25rem;
}
.dlabnav .header-info2 img {
  height: 3.563rem;
  width: 3.563rem;
  border-radius: 0.875rem;
  margin-right: 0.8rem;
}
.dlabnav .header-info2 span {
  font-size: 1.25rem;
  color: #171b1e;
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 span {
    font-size: 0.9rem;
  }
}
.dlabnav .header-info2 i {
  margin-left: 1rem;
  font-size: 1.2rem;
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 {
    padding: 0;
  }
}
.dlabnav .metismenu a {
  color: #8e8e93;
  display: block;
  outline-width: 0;
  padding: 0.625rem 1.875rem;
  position: relative;
  text-decoration: none;
}
.dlabnav .metismenu ul a.mm-active,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a:hover {
  color: #f9a05f;
  text-decoration: none;
}
.dlabnav .metismenu ul a {
  font-size: 0.9375rem;
  padding-bottom: 0.5rem;
  padding-left: 5rem;
  padding-top: 0.5rem;
  position: relative;
  transition: all 0.5s;
}
.dlabnav .metismenu ul a:before {
  background: rgba(68, 129, 78, 0.5);
  content: "";
  height: 0.125rem;
  left: 2.5rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s;
  width: 0.375rem;
}
.header-profile::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  top: 29px;
  height: 31px;
  background-color: #3a3a3c;
}
.image-area {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.image-area img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.header-profile-area {
  display: flex;
  align-items: center;
}

.person-name {
  color: white;
  margin-right: 20px;
  margin-left: 15px;
}
.porifle-picture {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden;
}
.drop-down-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.profile-user-name {
  margin: 0;
  font-weight: bold;
  line-height: 10px;
}
.profile-user-email {
  margin: 0;
  font-size: 12px;
  color: #8e8e93;
}
.profile-details {
  padding: 0px 10px;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
}
.dlabnav .dlabnav-scroll {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.menu-toggle .dlabnav .dlabnav-scroll {
  overflow: visible !important;
}
.menu-toggle .dlabnav {
  width: 6.5rem;
  overflow: visible;
  position: absolute;
}
.menu-toggle .dlabnav {
  position: fixed;
}
.menu-toggle .nav-header .brand-logo .brand-title {
  display: none;
}
.menu-toggle .dlabnav .metismenu > li > a {
  padding: 1.25rem 1.125rem;
  text-align: center;
  border-radius: 3rem;
}
.menu-toggle .dlabnav .nav-text {
  display: none;
}
.dlabnav .metismenu > li {
  transition: all 0.4s ease-in-out;
  padding: 0 2rem;
}
.menu-toggle .dlabnav .metismenu .has-arrow:after {
  display: none;
}
.menu-toggle .dlabnav .metismenu li > ul {
  position: absolute;
  left: 5.8rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 0.0625rem;
  height: auto !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgb(82 63 105 / 10%);
  border-radius: 0.375rem;
  margin-left: 0;
  border: 0;
  background: #fff;
}
.menu-toggle .dlabnav .metismenu > li > a {
  padding: 1.25rem 1.125rem;
  text-align: center;
  border-radius: 3rem;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 2;
  overflow: hidden;
  border-radius: 1.25rem;
  box-shadow: 0 0 3.125rem 0 rgb(82 63 105 / 15%);
  margin-top: 0;
}
.notification_dropdown .dropdown-menu-end {
  min-width: 19.375rem;
  padding: 0rem 0 1rem;
  top: 100%;
}
.height380 {
  height: 23.75rem;
}
.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #eaeaea;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.widget-media .timeline .timeline-panel .media {
  width: 3.125rem;
  height: 3.125rem;
  background: #eee;
  border-radius: 0.75rem;
  overflow: hidden;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}
.media-body {
  flex: 1 1;
}
.media img {
  border-radius: 0.1875rem;
}
.notification_dropdown .dropdown-menu-end .all-notification {
  display: block;
  padding: 0.9375rem 1.875rem 0;
  text-align: center;
  border-top: 0.0625rem solid #c8c8c8;
}
.chatbox {
  width: 24rem;
  height: 100vh;
  position: fixed;
  right: -31.25rem;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0rem 0rem 1.875rem 0rem rgb(82 63 105 / 15%);
  transition: all 0.8s;
}
.chatbox.active {
  right: 0;
}
.chatbox .chatbox-close {
  position: absolute;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 24rem;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}
.chatbox.active .chatbox-close {
  width: 100vw;
}
.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: var(--rgba-primary-1);
  border: 0;
  justify-content: space-between;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}
.tab-content > .active {
  display: block;
}
.fade {
  transition: opacity 0.15s linear;
}
.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 1.25rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
  height: calc(100% - 30px);
}
.chatbox .card {
  box-shadow: none;
}
.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}
.chatbox .card-header {
  background: #f4f7ff;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
}
.card-header:first-child {
  border-radius: calc(1.25rem - 1px) calc(1.25rem - 1px) 0 0;
}
.card-header {
  border-color: #d7dae3;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.active-border {
  color: white !important;
}

.menu-toggle .dlabnav .metismenu > li {
  padding: 0 1rem !important;
}

.content-body {
  margin-left: 20.563rem;
  z-index: 0;
  padding: 25px;
  transition: all 0.2s ease;
}
@media only screen and (max-width: 87.5rem) {
  .content-body {
    margin-left: 0;
  }
}
.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

.menu-toggle .content-body {
  margin-left: 6.5rem;
}

@media only screen and (max-width: 768px) {
  .menu-toggle .dlabnav {
    left: 0;
  }

  .dlabnav {
    
    transition: all 0.5s;
    box-shadow: 0 0 0.625rem rgb(0 0 0 / 20%);
  }
  .dashbord-media{
    flex-direction: column;
  }
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: #19191ba3;
  border: 0;
  justify-content: space-between;
}
.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: var(--primary-color);
  opacity: 1;
  border-color: var(--primary-color);
}
.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary-color);
  border-radius: 0;
  color: var(--primary-color);
  border-width: 0 0rem 0.1875rem 0;
}
.chatbox .nav .nav-link {
  color: white;
  opacity: 1;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8f8f8;
  border-color: #dee2e6 #dee2e6 #f8f8f8;
}
.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}

.dlabnav .metismenu > li > a:hover {
  border: 1px solid;
  border-image-source: radial-gradient(
    100% 3025% at 0% 50%,
    rgba(249, 160, 95, 0.3) 0%,
    #f9a05f 52.08%,
    rgba(249, 160, 95, 0.3) 100%
  );
  border-image-slice: 2;
  color: white;
}
.dashbaord-date-filter li {
  display: inline-block;
  padding: 0px 5px;
}

.info-number {
  display: block;
  color: #f9a05f;
  font-size: 18px;
  font-weight: 600;
}
.info-text {
  font-size: 12px;
  font-weight: 600;
}
/* .infoblocks{
  margin:0px 10px;
  min-width: 140px !important;
  background-color: white;
}
.infoblocks {
  background: white;
  max-width: fit-content;
  text-align: center;
  padding: 15px 20px;
  border-radius: 8px;
  position:relative;
}
.infoblocks:before {
  content: "";
  position: absolute;
  left: 25px;
  bottom: 0;
  height: 15px;
  width: 70%;
  border-bottom: 3px solid var(--primary-color);
} */
.info-ul li {
  display: inline-block;
  margin-left: 15pxpx;
  margin-bottom: 20px;
}
.btn-close {
  border: 0;
  border-radius: 1.25rem;
  box-sizing: content-box;
  color: #000;
  opacity: 0.5;
  font-size: 17px;
}

.alert-dismissible .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.h3,
h3 {
  font-size: 1.3rem;
}
.alerts-card {
  max-height: 80vh;
  overflow: scroll;
}
.chart-block {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
}

.menu-item-title {
  color: #2c2c2e;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0;
}
.meni-image {
  height: 60px;
  margin-right: 10px;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}
.menu-item-price {
  color: #f9a05f;
  float: right;
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 0px;
}
.menu-block-table {
  width: 100%;
}
.menu-item-description {
  margin-bottom: 0px;
}
.menu-item-search-area {
  margin-bottom: 20px;
}
.inner-header {
  font-size: 20px;
  margin-bottom: 0px;
}
.title-sub-text {
  color: #aeaeb2;
  font-size: 12px;
  margin: 0;
  line-height: 12px;
}
.menu-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.main-btn-add {
  font-weight: 500;
}
.plus-icon {
  margin-right: 10px;
  font-size: 16px;
}

.btn-theme-main {
  background: #f9a05f;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
  color: #1c1c1e;
  border: none;
  transition: 0.3s;
}

.btn-theme-main:hover {
  background: #1c1c1e;
  color: #f9a05f;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 8px 8px 0px 0px;
}

.header-left-row ul {
  padding: 0px;
  margin: 0;
}

.header-left-row ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 700;
}
.exp-coll {
  font-weight: 700;
  cursor: pointer;
}

.category-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-top: 10px;
  margin-bottom: 8px;
}
.category-title h5 {
  margin: 0px;
}
.category-description p {
  margin: 0px;
}
.category-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.category-title {
  margin: 0px 12px;
}
.category-left {
  display: flex;
  align-items: center;
}
.category-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-top: 10px;
  margin-bottom: 8px;
  padding: 10px 20px;
}
.left-menu-item {
  width: 95%;
  margin-left: 5%;
}
.main-block {
  cursor: move;
  padding: 0px 20px 0px 10px;
}
.products-collapse {
  display: none;
}
.sorting-handle {
  color: #d9d9d9;
}
.drop-down-hr {
  margin: 3px auto;
  border: solid 1px #c7c7cc;
  max-width: 80%;
}
.dropdown-item {
  color: #2c2c2e !important;
}
.dropdown-menu {
  font-size: 14px !important;
}
.dashbaord-header-button li {
  display: inline-block;
  padding: 0px 10px;
}
.btn-theme-secondary {
  background: #e5e5ea;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
  color: #1c1c1e;
  border: none;
  transition: 0.3s;
}
.btn-theme-secondary:hover {
  color: #f9a05f;
  background: black;
}
.slim-button-theme {
  padding: 3px 30px;
  min-width: 130px;
}
.dashbaord-header-nav {
  padding: 0;
  border-bottom: 1px solid #c7c7cc;
  width: 50%;
}
.dashbaord-header-nav li {
  padding: 5px 10px;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}
.dashbaord-header-nav li:hover {
  border-bottom: 2px solid #f9a05f;
}
.active-menu-nav {
  border-bottom: 2px solid #f9a05f;
}
.secondary-color-link {
  color: #33415c;
}
.template-filter-menu {
  padding: 0px;
}
.template-filter-menu li {
  display: inline-block;
}
.template-filter {
  border: none;
  background: white;
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 12px;
  border-radius: 50px;
  transition: 0.3s;
}
.template-filter:hover {
  background: #f9a060;
}
.active-template-filter {
  background: #f9a060;
}
.template-image {
  border-radius: 8px;
  overflow: hidden;
}
.template-area-block {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  min-height: 300px;
}
.template-details {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.dashbaord-themplate-button {
  padding: 0;
  text-align: center;
}
.template-details {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
}
.dashbaord-themplate-button li {
  display: inline-block;
  margin: 0px 5px;
}
.themplate-btn {
  background: #f9a060;
  border: 0;
  padding: 5px 20px;
  border-radius: 3px;
}
.themplate-btn:hover {
  color: #f9a060;
  background: black;
}
.template-content p {
  margin: 0px;
  line-height: 18px;
  font-weight: 500;
}

.template-details:hover > .template-buttons {
  animation-name: template-btn-down;
  animation-duration: 1s;
  bottom: 20px;
}
.template-details:not(:hover) > .template-buttons {
  animation-name: not-template-btn-down;
  animation-duration: 1s;
}
.template-details:not(:hover) > .template-content {
  animation-name: not-template-top;
  animation-duration: 1s;
}
.template-details:hover > .template-content {
  animation-name: template-top;
  animation-duration: 1s;
  top: 20px;
}
.template-content {
  position: absolute;
  top: -100px;
  text-align: center;
  width: 100%;
}
.template-buttons {
  position: absolute;
  bottom: -100px;
  text-align: center;
  width: 100%;
}
@keyframes template-top {
  0% {
    top: -100px;
  }
  100% {
    top: 20px;
  }
}
@keyframes template-btn-down {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 20px;
  }
}
@keyframes not-template-top {
  0% {
    top: 20px;
  }
  100% {
    top: -100px;
  }
}
@keyframes not-template-btn-down {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: -100px;
  }
}
.active-item-template {
  border: solid 2px #f9a060;
  border-radius: 12px;
  width: 254px;
}
.active-item-template {
  border: solid 2px #f9a060;
  border-radius: 12px;
  width: 254px;
}

.dashbaord-edit-menu-nav {
  padding: 0;
  border-bottom: 1px solid #c7c7cc;
}
.dashbaord-edit-menu-nav li {
  padding: 5px 10px;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}
.dashbaord-edit-menu-nav li:hover {
  border-bottom: 2px solid #f9a05f;
}
/* My new css */

.form-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-group-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}
.edit-menu-area {
  padding: 30px;
  background: white;
  border-radius: 20px;
}
.form-area-menu label {
  font-weight: 500;
}

.active-item-template .template-buttons {
  bottom: 20px !important;
}

.active-item-template .template-content {
  top: 20px !important;
}
.template-menu-content {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
}
.content-right .btn-link {
  color: white;
}
.modal-header {
  text-align: center;
}
.modal-title {
  width: 100% !important;
  font-size: 1rem !important;
}
.modal-buttons-list {
  padding: 0;
  text-align: center;
}
.modal-buttons-list li {
  display: inline-block;
  margin: 10px;
}
.form-group-yawaevig {
  margin-bottom: 20px;
}
.btn-close {
  font-size: 14px;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(249 160 97 / 56%);
  opacity: 1;
}
.yawaevig-form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.item-setting-form lable {
  font-weight: 500;
}
.yawaevig-form-row .form-group-yawaevig {
  width: 48%;
}
.item-setting-preview {
  max-width: 150px;
}
.images-list-suggestion ul {
  padding: 0;
}
.images-list-suggestion li {
  display: inline-block;
}
.item-image-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.images-list-suggestion li {
  display: inline-block;
  margin: 0px 5px;
}
.images-list-suggestion img {
  width: 65px;
  height: 65px;
}
.suggested-images-area {
  margin-top: 15px;
}
.images-list-suggestion {
  padding: 0;
}
.upload-file-field lable {
  display: block;
}
.right-image-preview lable {
  display: block;
}
.switch-btns-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.left-btns-menu {
  width: 48%;
}
.right-btns-menu {
  width: 48%;
}
.div-flex-andspaceb {
  display: flex;
  justify-content: space-between;
}
.main-content {
  max-width: 94%;
}
.preview-area {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.dot-area {
  width: 8px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}
.dots-list {
  padding: 0;
  text-align: center;
}
.dots-list li {
  display: inline-block;
  margin: 0px 6px;
}
.active-dot {
  background: #f9a05f;
}
.ordering {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.ordering button {
  margin-bottom: 10px;
}
.main-content {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}
.screen-area {
  border-radius: 15px;
  border: solid 8px #cacaca;
  margin: 0px 15px;
  height: 530px;
  width: 260px;
  background: #f7f7f7;
}
.preview-header-img {
  max-height: 150px;
  overflow: hidden;
  border-radius: 8px 8px 10px 10px;
}
.logo-social-area {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 10px;
}
.social-media-icons ul {
  padding: 0;
}
.logo-social-area img {
  width: 70px;
  margin-top: -317px;
  border-radius: 5px;
}
.social-media-icons li {
  display: inline-block;
  margin-left: 10px;
}
.h6,
h6 {
  font-size: 0.8rem;
}
.resturnet-title-area {
  padding: 0px 10px;
}
.resturnet-location {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}
.description-area p {
  line-height: 15px;
  margin: 0;
}
.open-or-close p {
  margin: 0;
}
.description-area {
  padding: 10px 10px;
}
.description-text {
  font-weight: 600;
  margin-bottom: 8px !important;
}
.template-detail-name p {
  line-height: 16px;
  margin: 5px;
  text-align: center;
}
.template-detail-name {
  margin: 20px 0px;
}
.resturent-location-area .fa {
  color: #f9a05f;
}
.open-or-close p {
  margin: 0;
  color: #3eea00;
}
.preview-address {
  font-size: 12px;
  color: #8e8e93;
}
.before-open {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #3eea00;
  margin-right: 5px;
  border: solid 4px #3eea0057;
}
.description-details {
  font-size: 12px;
  color: #8e8e93;
}
.preview-menu-order-btn {
  display: flex;
  justify-content: space-between;
  background: #f2f2f7;
  border: none;
  padding: 7px 10px;
}
.left-btn-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 79px;
}
.category-image-preview {
  max-width: 80px;
}
.screen2-header {
  color: #f9a060;
  background: #1c1c1e;
  padding: 20px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
}
.categories-tabs ul {
  padding: 0;
  margin: 0px;
}
.active-category-tab {
  background: #f9a060 !important;
}
.categories-tabs ul li {
  display: inline-block;
  margin: 5px 5px;
  background: #f5f6fa;
  font-size: 10px;
  padding: 0px 12px;
  border-radius: 100px;
}
.screen2-header {
  color: #f9a060;
  background: #1c1c1e;
  padding: 10px;
}
.product-block {
  display: flex;
  align-items: center;
  background: white;
  margin-bottom: 10px;
}
.products-area-preview {
  padding: 0px 10px;
  /* overflow:scroll; */
  /* max-height:200px; */
}
.product-block-right {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.row2-right-product-price {
  margin-left: 25px;
}
.product-block-row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-block-row2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.product-name-block {
  margin: 0;
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
}
.row2-left-about-product ul {
  padding: 0;
  margin: 0px;
  font-size: 9px;
}
.product-category-name-block {
  margin: 0px;
  font-size: 10px;
  line-height: 12px;
}
.row2-left-about-product ul li {
  display: inline-block;
  padding: 0px 5px;
}
.screen-search-area {
  padding: 10px 0;
}
.screen-search-area .menu-item-search-area {
  margin: 0;
}
.screen-search-area .form-control {
  font-size: 10px;
  background-color: #edecec;
}
.screen-search-area .input-group-text {
  font-size: 10px;
}
.categories-area {
  padding: 0px 10px;
}

.top-categories-lable {
  margin: 0px 0px 4px 0px;
  font-weight: 500;
}
.category-block {
  position: relative;
  width: fit-content;
}
.category-block .category-title-preview {
  position: absolute;
  bottom: 0px;
  left: 4px;
  color: white;
  font-weight: 500;
  font-size: 12px;
}
.category-image-preview {
  width: 70px;
  border-radius: 10px;
}
.preview-category-overlay {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  height: 27px;
  width: 100%;
  position: absolute;
  border-radius: 0px 0px 10px 10px;
  bottom: 0;
}
.preview-product-image {
  width: 60px;
  border-radius: 4px;
}
.product-block-fav-area {
  font-size: 12px;
}
.checkout-cart-area-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1c1c1e;
  color: white;
  border-radius: 10px 10px 6px 6px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 9;
}
.left-area {
  border: solid 1px #d5d5d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 10px;
  color: #df8f56;
  cursor: pointer;
}
.right-area {
  border: solid 1px #d5d5d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 10px;
  color: #df8f56;
  cursor: pointer;
}
.screen-content {
  position: relative;
  height: 100%;
}

.total-dim-text {
  font-size: 12px;
  color: #8e8e93;
  text-align: center;
  line-height: 12px;
}
.footer-total-text {
  margin: 0;
  line-height: 12px;
}
.preview-button-theme {
  background: #f59f5f;
  color: black;
  font-size: 12px;
  border: none;
}
.btn-cart-ison {
  margin-right: 6px;
}
.quality-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-quality-btn {
  border: 0;
  background: #f9a061;
  padding: 5px;
  margin: 0;
  line-height: 10px;
  font-size: 16px;
}
.popup-product-area-preview {
  height: 80%;
  background: white;
  margin: 16px;
  position: absolute;
  padding: 10px;
  bottom: 0;
  z-index: 2;
  border-radius: 10px;
}
.popup-header {
  margin-bottom: 20px;
}
.preview-quality-number {
  margin: 0px 10px;
}
.products-area-screen-3 {
  margin-bottom: 10px;
}
.comment-form-group {
  margin-top: 20px;
}
.comment-form-group lable {
  font-weight: 500;
  margin-bottom: 9px;
  display: block;
}
.product-description-preview-block {
  font-size: 8px;
  line-height: 9px;
  max-width: 125px;
  margin: 0px;
}
.product-time0-areea {
  line-height: 14px;
}
.preview-overlay-area-s3 {
  height: 100%;
  width: 100%;
  background: #000000a1;
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 10px;
}
.content-padding-area {
  padding: 10px;
}
.preview-main-title {
  margin: 0;
  font-weight: 500;
}
.categories-tabs {
  margin-top: 10px;
  margin-bottom: 15px;
}
.payment-summery-area table {
  width: 100%;
  font-size: 12px;
}
.payment-summery-area {
  margin-bottom: 10px;
}
.display-center {
  display: block;
}
.inner-screen-area {
  margin: 0 auto;
}
/* qr Pages */
.qr-header-image {
  max-height: 250px;
  overflow: hidden;
}
.qr-header-image img {
  width: 100%;
}
.qr-logo-main {
  max-width: 100px;
  margin-top: -50px;
  margin-left: 10px;
}
.qr-after-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qr-scan-ordering-btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f7;
  margin: 10px 0px;
  border: none;
  border-radius: 8px;
}
.qr-mobile-screen {
  background: white;
}
.qr-mobile-screen {
  background: white;
  min-height: 100vh;
}
.tutorial-list {
  padding: 0px;
}
.turorial-section-heading {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 500;
}
.tutorial-question {
  line-height: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}
.tutorial-content-area {
  padding: 10px 20px;
}
.tutorial-content-block {
  padding: 10px;
}
.tutorial-question {
  line-height: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-bottom: solid 0.5px lightgray;
  padding-bottom: 5px;
}
.faq-content-area {
  padding: 20px;
}
.faq-answer-question h4 {
  font-size: 16px;
}
.timeline-panel h6 {
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
}
.timeline-panel .d-block {
  line-height: 11px;
}
.p-l-0 {
  padding-left: 0px;
}

.graph-indication {
  /* display: flex;
  justify-content: space-between; */
}

.graph-indication li {
  position: relative; /* add this line */
  list-style: none;
  padding-left: 25px;
}

.graph-indication li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.graph-indication li:nth-child(2) {
  margin-left: 20px;
}

.graph-indication li:nth-child(1)::before {
  background-color: #f9a05f;
}

.graph-indication li:nth-child(2)::before {
  background-color: #af52de;
}

/* .graph-indication li:nth-child(1) {
  list-style: none;
  padding-left: 10px;
  background-color: red;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.graph-indication li:nth-child(2) {
  list-style: none;
  padding-left: 10px;
  background-color: green;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
} */

.graph-indication li {
  float: left;
}

.close-model-button {
  position: absolute;
  right: 0;
}

.dropdown-item {
  cursor: pointer;
}

.service {
  border-radius: 5px;
  background-color: #f9a05f;
  color: white;
  padding: 3px 3px;
  margin-right: 2px;
}

.giveaway-login {
  color: white !important;
  background-color: #00a5d1;
  padding: 3px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
}

.item-section {
  color: black;
  background-color: #c3c5c9;
  border-radius: 15px;
  padding: 5px 15px;
  margin-right: 5px;
  font-size: 10px;
}

.item-section.active {
  background-color: #df8f56;
}

.services-card .card-img {
  width: 70px;
}

.services-card .cad-title {
  font-size: 12px;
}

.services-card .dim-text {
  font-size: 10px;
  margin: 0;
  line-height: 1;
}

.slots {
  background-color: white;
  min-height: 280px;
}

.react-datepicker {
  width: 100%;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
  color: #f9a05f;
}

.react-datepicker__month div {
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
}

.react-datepicker__day[aria-selected="true"] {
  /* color:#F9A05F!important; */
  /* width: 200px!important; */
  border-radius: 18px;
  background-color: #f9a05f !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: transparent !important;
}

.react-datepicker__day--selected {
}

.react-datepicker__current-month {
  text-align: left;
  padding-left: 16px;
}

.react-datepicker__input-container {
  width: 100%;
}

.date-calander .react-datepicker__month-container {
  float: none;
  width: 85%;
  margin: auto;
}

.date-calander input {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px lightgray solid;
  width: 125px;
  margin: 0 !important;
  padding: 0px 8px !important;
}

.react-datepicker__input {
  width: 100%;
}

.custom-calander > div:first-child {
  width: 100% !important;
  border: transparent !important;
}

.custom-calander > div:first-child > section:nth-child(2) > div {
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
}

.custom-calander
  > div:first-child
  > section:nth-child(2)
  > div:first-child
  > span {
  color: #f9a05f;
}

.custom-calander
  > div:first-child
  > section:nth-child(2)
  div
  button[aria-pressed="true"] {
  background: #f9a05f !important;
  color: black;
}

body {
  background-color: white !important;
}

.order-detail .card-header label {
  font-weight: bold;
}

.bill-detail th,
.bill-detail td {
  padding: 0 !important;
}

.time-slot {
  cursor: pointer;
}

.react-datepicker__navigation-icon--previous {
  right: 13px !important;
}

.react-datepicker__navigation-icon--next {
  left: 12px !important;
}
